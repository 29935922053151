<template>
    <div>
        <header-portrate />

    <div class="m-2" style="margin-bottom:70px !important;">
        <bread-crumbs :items="items" />
        <tabs-comp :tabs="tabs" />

        <v-row :style="`direction:`+lang.dir">
            <v-col
            cols="12"
            md="5"
            sm="12">
            <b-input-group class="mt-3">
                <b-form-input placeholder="Start Date" style="background:#eee" type="date" v-model="sdate"></b-form-input>
                <b-form-input placeholder="End Date" style="background:#eee" type="date" v-model="edate"></b-form-input>
                <b-input-group-append style="border-left:1px solid #CCC">
                    <b-button variant="light" style="background: #ccc !important;font-size:14px;color:#000;font-size:14px;padding:8px 22px;border-radius:5px" @click="getReport()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                </b-input-group-append>
            </b-input-group>
            </v-col>
            <v-col cols="12" md="7" sm="12" class="text-right">
                <b-button variant="light" style="width:145px;margin-right:9px" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="5" class="text-center">{{lang.incomes}}</th>
                        </tr>
                        <tr>
                            <th>#</th>
                            <th class="text-center">{{lang.date}}</th>
                            <th class="text-center">{{lang.description}}</th>
                            <th class="text-center">{{lang.amountprice}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in incomerows" :key="index">
                            <td >{{item.count}}</td>
                            <td class="text-center" style="direction:ltr">{{item.date}}</td>
                            <td class="text-center" style="direction:ltr">{{item.description}}</td>
                            <td class="text-center" style="direction:ltr">{{item.amount}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>{{lang.totals}}</th>
                            <th>{{intotal}}</th>
                        </tr>
                    </tfoot>
                </v-simple-table>
                <br>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="2">{{lang.itotals_per_paymethod}}</th>
                        </tr>
                        <tr v-for="(item,index) in incometypes" :key="index">
                            <th class="text-center" style="border:1px solid #ccc">{{item.name}}</th>
                            <td class="text-center" style="border:1px solid #ccc">{{item.sumd}}</td>
                        </tr>
                    </thead>
                </v-simple-table>
            </v-col>
            <v-col cols="12" md="6" sm="12">
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="5" class="text-center">{{lang.expenses}}</th>
                        </tr>
                        <tr>
                            <th>#</th>
                            <th class="text-center">{{lang.date}}</th>
                            <th class="text-center">{{lang.description}}</th>
                            <th class="text-center">{{lang.amountprice}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in outcomesrows" :key="index">
                            <td >{{item.count}}</td>
                            <td class="text-center" style="direction:ltr">{{item.date}}</td>
                            <td class="text-center" style="direction:ltr">{{item.description}}</td>
                            <td class="text-center" style="direction:ltr">{{item.amount}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>{{lang.totals}}</th>
                            <th>{{outtotal}}</th>
                        </tr>
                    </tfoot>
                </v-simple-table>
                <br>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th colspan="2">{{lang.ototals_per_paymethod}}</th>
                        </tr>
                        <tr v-for="(item,index) in outcometypes" :key="index">
                            <th class="text-center" style="border:1px solid #ccc">{{item.name}}</th>
                            <td class="text-center" style="border:1px solid #ccc">{{item.sumc}}</td>
                        </tr>
                    </thead>
                </v-simple-table>
            </v-col>
        </v-row>
    </div>

        <Footer />
    </div>

</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default {
  components: { breadCrumbs ,TabsComp, HeaderPortrate, Footer},
    data()
     {
        return {
            sdate: new Date().toISOString().substring(0, 10),
            edate: new Date().toISOString().substring(0, 10),
            incomerows: [],
            outcomesrows:[],
            incometypes: [],
            outcometypes: [],
        }
    },
    computed: {
        intotal: function(){
            let t = 0;
            for(let i=0;i<this.incomerows.length;i++){
                t = parseFloat(t) + parseFloat(this.incomerows[i].amount);
            }
            return this.$RoundNum(t);
        },
        outtotal: function(){
            let t = 0;
            for(let i=0;i<this.outcomesrows.length;i++){
                t = parseFloat(t) + parseFloat(this.outcomesrows[i].amount);
            }
            return this.$RoundNum(t);
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            return {
                    text: this.lang.income_report,
                    disabled: true,
                    to: '/reports/income-report',
                }
        },
        tabs: function() {
            let tabs =  [
                {
                    index:3,name:this.lang.vat_report,href:'/reports/vat-reports',class:'mytab3',smclass:'font-small'
                },
               
            ];
        
            tabs.push(
                {
                    index:4,name:this.lang.income_report,href:'/reports/income-report',class:'mytab',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:7,name:this.lang.account_statement,href:'/reports/statment-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:8,name:this.lang.account_balance_report,href:'/reports/balance-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:8,name:this.lang.bnl_report,href:'/reports/bnl-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:8,name:this.lang.balance_report,href:'/reports/acbalance-report',class:'mytab3',smclass:'font-small'
                }
            );
            tabs.push(
                {
                    index:2,name:this.lang.sales_report,href:'/reports/sales-reports',class:'mytab3',smclass:'font-small'
                }
            );
            
            tabs.push({
                    index:5,name:this.lang.return_report,href:'/reports/return-reports',class:'mytab6',smclass:'font-small'
                }
            );
            tabs.push({
                    index:0,name:this.lang.received_voucher,href:'/finance/inreceipt',class: 'mytab4',smclass:'font-small'
                }
            );
            tabs.push({
                    index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab5',smclass:'font-small'
                }
            );

            return tabs;
        },
        headers: function(){
            return '';
        }
    },
    created() {
        this.getReport();
    },
    methods: {
        getReport(){
            const post = new FormData();
            post.append('type',"getIncomeReport");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                if(typeof res.results.data !== 'undefined')
                {
                    if(typeof res.results.data[0] !== 'undefined'){
                        this.incomerows = res.results.data[0].depit;
                    }
                    if(typeof res.results.data[0] !== 'undefined'){
                        this.outcomesrows = res.results.data[0].credit;
                    }
                    if(typeof res.results.data[1] !== 'undefined'){
                        this.incometypes = res.results.data[1].depit;
                        this.outcometypes = res.results.data[1].credit;
                    }
                }
            })
        }
    },
}
</script>